.App {
  background: #24272a;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: visibility ease-in 2s;
  height: unset;
  width: unset;
  transform: translate(-50%, -50%);
  position: fixed;
  left: 50%;
  top: 50%;

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.columnEnd {
      align-items: flex-end;
      margin-right: 0.5rem;
    }

    &.columnStart {
      align-items: flex-start;
      margin-left: 0.5rem;
    }
  }

  .mission {
    margin-bottom: 1.92vw;

    font-family: Orbitron, serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.744vw;
    line-height: 2.208vw;
    color: #f7f7f7;

    .missionName {
      color: #da7730;
    }
  }

  .buttonWrapper {
    visibility: visible;

    .buttonSubText {
      color: lightgray;
      font-size: 0.56vw;
      margin-top: -0.05vw;
      margin-left: 0.03vw;
      font-family: Orbitron, serif;
    }
  }


  .playerName {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 39vw;

    .playerNameInput{
      background: rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      transition: all 0.24s;
      padding: 10px;
      line-height: normal;
      font-size: 1.2vw;
      vertical-align: top;
      outline: none;
      color: white;
      box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
      border: none;

      &:hover {
        background: #00000068;
      }

      &:focus {
        background: #00000097;
        box-shadow: 0 0 20px rgb(0 0 0 / 40%), 0 0 5px rgb(0 0 0 / 30%),
        0 0 0 2px #00d0ff;
      }
    }
  }

  .difficulty {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 39vw;

    .pleaseSelect {
      color: white;
      font-size: 0.54vw;
      font-family: Orbitron, serif;
      margin-bottom: 0.27vw;
    }

    .difficultyOptions {
      position: relative;
      display: flex;
      gap: 0.2vw;

      .difficultyOption {
        position: relative;
        display: flex;
        align-items: center;
        width: 6.4vw;
        height: 1.6vw;
        cursor: pointer;

        font-size: .8vw;
        font-family: Orbitron, serif;
        color: white;

        .difficultyText {
          margin: 0 0.64vw;

          &.selected {
            z-index: 1;
          }
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }

        &.left {
          justify-content: flex-end;

        }
        &.center {
          width: 4.8vw;
          justify-content: center;
        }
        &.right {
          justify-content: flex-start;
          .difficultyText {
            margin: 0.4vw;
          }
        }
      }
    }
  }

  &.hasBeenADay {
    width: 100vw;
    height: 100vw;
  }

  &.iframeShown {
    visibility: hidden;

    .buttonWrapper {
      visibility: hidden;
    }
  }
}

.footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100vw;
  z-index: 12;

  .footerBorder {
    position: absolute;
    bottom: 2.3vh;
    height: 8.5vh;
  }

  .footerBackground {
    position: absolute;
    bottom: -1.7vh;
    height: 12.3vh;
    width: 200vh;
  }
}

.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  top: 0;
  z-index: 12;

  .headerBorder {
    position: absolute;
    top: 2.3vh;
    height: 8.5vh;
  }

  .headerBackground {
    position: absolute;
    top: -1.7vh;
    height: 12.3vh;
    width: 200vh;
  }

  .logo {
    position: absolute;
    top: -1.7vh;
    width: 39.8vh;
    height: 14.9vh;
    z-index: 1;
    transition: top ease-in 5s;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &.logoDisappear {
      top: -9.3vw;
    }
  }
}

.iframe {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 12;
  width: 100vw;
  height: 100vh;
  transition: opacity 5s ease-out;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;

  &.shown {
    visibility: visible;
    opacity: 1;
  }
}

.apology {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: opacity 5s ease-out;
  visibility: hidden;
  opacity: 0;

  font-family: Orbitron, serif;
  color: white;
  text-align: center;

  .title {
    font-size: 5vh;
    margin-bottom: 2vh;
  }

  .desc {
    font-size: 3vh;
  }

  &.shown {
    visibility: visible;
    opacity: 1;
  }
}

@media (max-width: 1024px) and (orientation: portrait) {
  .App {
    .mission {
      margin-bottom: 5.76vw;

      font-size: 5.232vw;
      line-height: 6.624vw;
    }

    .row {
      flex-wrap: unset;
      transform: translateX(-30%);

      .column {
        transform: scale(0.95);
        margin: 0;
      }
    }

    .buttonWrapper {
      .buttonSubText {
        font-size: 1.65vw;
        margin-top: -0.15vw;
        margin-left: 0.09vw;
        font-family: Orbitron;
      }
    }

    .playerName{
      bottom: 10vw;
    }

    .difficulty {
      bottom: 10vw;

      .pleaseSelect {
        font-size: 1.8vw;
        margin-bottom: 0.9vw;
      }

      .difficultyOptions {
        gap: 1.2vw;

        .difficultyOption {
          width: 24vw;
          height: 6vw;

          font-size: 3vw;

          &.center {
            width: 18vw;
          }

          .difficultyText {
            margin: 0 2.4vw;
          }
        }
      }
    }
  }

  .header {
    width: 100vw;

    .logo {
      top: -1vw;
      width: 79.6vw;
      height: 29.8vw;

      &.logoDisappear {
        top: -18.6vw;
      }
    }
  }

  .apology {
    .title {
      font-size: 3vh;
      margin-bottom: 2vh;
    }

    .desc {
      font-size: 2vh;
    }
  }

  @media(max-width: 768px) and (orientation: portrait) {
    .App {
      .playerName {
        .playerNameInput {
          font-size: 3.2vw;
        }
      }
    }
  }

  @media (min-aspect-ratio: 1/2) {
    .App {
      .mission {
        margin-bottom: 3.84vw;

        font-size: 3.488vw;
        line-height: 4.416vw;
      }
    }

    .header {
      width: 100vw;

      .logo {
        top: -1vw;
        width: 53.06vw;
        height: 19.86vw;

        &.logoDisappear {
          top: -12.3vw;
        }
      }
    }
  }
}
