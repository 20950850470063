.buttonGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  visibility: hidden;
  opacity: 0;

  &.shown {
    visibility: visible;
    opacity: 1;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.columnEnd {
      align-items: flex-end;
      margin-right: 0.5rem;
    }

    &.columnStart {
      align-items: flex-start;
      margin-left: 0.5rem;
    }

    .buttonWrapper {
      visibility: visible;

      .buttonSubText {
        color: lightgray;
        font-size: 0.56vw;
        margin-top: -0.05vw;
        margin-left: 0.03vw;
        font-family: Orbitron, serif;
      }
    }
  }

  @media (max-width: 1024px) and (orientation: portrait) {
    flex-wrap: unset;
    transform: translateX(-30%);

    .column {
      transform: scale(0.95);
      margin: 0!important;

      .buttonWrapper {
        .buttonSubText {
          font-size: 1.65vw;
          margin-top: -0.15vw;
          margin-left: 0.09vw;
        }
      }
    }

    &.inModal {
      transform: translateX(-25%) scale(0.35);
    }
  }
}