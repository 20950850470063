.buttonRight {
  position: relative;
  display: flex;
  align-items: center;
  width: 12vw;
  z-index: 1;
  cursor: pointer;
  margin-top: -0.8vw;
  margin-right: 6.5vw;

  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;

  .buttonRightText {
    position: absolute;
    width: 100%;
    top: 30%;
    left: 2%;
    transform: translateY(-65%);

    font-weight: 400;
    font-size: 1.5vw !important;
    line-height: 1.17vw;
    font-family: Orbitron, serif;
    color: white;
    white-space: nowrap;
    text-align: left;

    .secondWordRight {
      color: #da7730;
    }
  }

  .secondWordRightText {
    position: absolute;
    width: 100%;
    top: 60%;
    left: 2%;
    transform: translateY(-65%);

    font-weight: 400;
    font-size: 1.5vw !important;
    line-height: 1.17vw;
    font-family: Orbitron, serif;
    color: #da7730;
    white-space: nowrap;
    text-align: left;
  }

  .buttonDecorationBorderRight {
    position: absolute;
    top: 1vw;
    right: -0.25vw;
    width: 3.696vw;
    height: 1.92vw;
    transition: all 700ms;
  }

  &:hover {
    .buttonRightBackground {
      display: inline-block;
    }

    .secondWordRightText {
      color: #ffd0ae;
    }

    .buttonDecorationBorderRight {
      transform: translate(0.36vw, 0.36vw);
    }
  }

  &:active {
    .buttonRightBackground {
      opacity: 0.8;
    }

    .buttonDecorationBorderRight {
      transform: translate(0.24vw, 0.24vw);
    }
  }

  &.scaled {
    width: 19.872vw;
    height: 4.5vw;
    margin-top: 4.176vw;

    .buttonRightText {
      font-size: 1.656vw;
      line-height: 2.1024vw;
    }

    .buttonDecorationBorderRight {
      top: -0.8vw;
      right: 2.8vw;
      width: 9vw;
      height: 6vw;
    }

    &:hover {
      .buttonDecorationBorderRight {
        transform: translate(0.648vw, 0.648vw);
      }
    }

    &:active {
      .buttonDecorationBorderRight {
        transform: translate(0.432vw, 0.432vw);
      }
    }
  }

  @media (max-width: 1024px) and (orientation: portrait) {
    width: 36vw;
    margin-top: -2.4vw;
    margin-right: 19.5vw;

    .buttonRightText {
      font-size: 4.5vw !important;
      line-height: 3.51vw;
    }

    .secondWordRightText {
      font-size: 4.5vw !important;
      line-height: 3.51vw;
    }

    .buttonDecorationBorderRight {
      top: 3vw;
      right: -0.75vw;
      width: 11.088vw;
      height: 5.76vw;
    }

    &:hover {
      .buttonDecorationBorderRight {
        transform: translate(1.08vw, 1.08vw);
      }
    }

    &:active {
      .buttonDecorationBorderRight {
        transform: translate(0.72vw, 0.72vw);
      }
    }

    &.scaled {
      width: 59.616vw;
      height: 13.5vw;
      margin-top: 12.528vw;

      .buttonRightText {
        font-size: 4.968vw;
        line-height: 6.3072vw;
      }

      .buttonDecorationBorderRight {
        top: -2.4vw;
        right: 8.4vw;
        width: 27vw;
        height: 18vw;
      }

      &:hover {
        .buttonDecorationBorderRight {
          transform: translate(1.944vw, 1.944vw);
        }
      }

      &:active {
        .buttonDecorationBorderRight {
          transform: translate(1.296vw, 1.296vw);
        }
      }
    }
  }
}
