.my-link-iframe {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  width: 25%!important;
  height: 100vh!important;
  background-image: linear-gradient(to top, rgb(35, 43, 62) 0%, rgb(14, 17, 24) 100%);
  z-index: 1234;
  transition: all 1s;
}

@media (max-width: 1024px) and (orientation: portrait) {
  .my-link-iframe {
    left: 0;
    width: 100vw!important;
  }
}
